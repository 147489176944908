// TransportPubliczny.js

import React, { useState } from 'react';
import {
    Tabs,
    Tab,
    Box,
    Grid,
    Card,
    CardContent,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import TramIcon from '@mui/icons-material/Tram';
import TrainIcon from '@mui/icons-material/Train';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import PedalBikeIcon from '@mui/icons-material/PedalBike';
import ElectricScooterIcon from '@mui/icons-material/ElectricScooter';

const TransportPubliczny = () => {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleBackButtonClick = () => {
        window.history.back();
    };

    const transportData = {
        Tradycyjny: [
            {
                name: 'Autobusy',
                icon: <DirectionsBusIcon fontSize="large" color="primary" />,
                emisjaCO2: 'Emisja CO2 dziennie: 150 kg',
                details: 'Autobusy miejskie przewożą średnio 150 pasażerów dziennie, emitując około 150 kg CO2.',
            },
            {
                name: 'Tramwaje',
                icon: <TramIcon fontSize="large" color="primary" />,
                emisjaCO2: 'Emisja CO2 dziennie: 120 kg',
                details: 'Tramwaje są bardziej ekologiczne niż autobusy, emitując około 120 kg CO2 dziennie.',
            },
            {
                name: 'Pociągi',
                icon: <TrainIcon fontSize="large" color="primary" />,
                emisjaCO2: 'Emisja CO2 dziennie: 200 kg',
                details: 'Pociągi przewożą duże ilości pasażerów z emisją około 200 kg CO2 dziennie.',
            },
            {
                name: 'Samochody Osobowe',
                icon: <DirectionsCarIcon fontSize="large" color="primary" />,
                emisjaCO2: 'Emisja CO2 dziennie: 300 kg',
                details: 'Średnia emisja CO2 z samochodów osobowych wynosi około 300 kg dziennie.',
            },
        ],
        Eko: [
            {
                name: 'Rowery',
                icon: <PedalBikeIcon fontSize="large" color="success" />,
                emisjaCO2: 'Emisja CO2 dziennie: 0 kg',
                details: 'Rowery nie emitują CO2, są najczystszym środkiem transportu.',
            },
            {
                name: 'Pieszo',
                icon: <DirectionsWalkIcon fontSize="large" color="success" />,
                emisjaCO2: 'Emisja CO2 dziennie: 0 kg',
                details: 'Spacerowanie jest w pełni ekologiczne, nie generuje emisji CO2.',
            },
            {
                name: 'Hulajnogi',
                icon: <ElectricScooterIcon fontSize="large" color="success" />,
                emisjaCO2: 'Emisja CO2 dziennie: 10 kg',
                details: 'Hulajnogi elektryczne emitują minimalne ilości CO2, około 10 kg dziennie.',
            },
        ],
    };

    return (
        <Box sx={{ padding: 4 }}>
            <Button variant="outlined" onClick={handleBackButtonClick} sx={{ mb: 2 }}>
                Wróć
            </Button>
            <Typography variant="h4" gutterBottom align="center">
                Transport Publiczny
            </Typography>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                centered
                textColor="primary"
                indicatorColor="primary"
            >
                <Tab label="Tradycyjny" />
                <Tab label="Eko" />
            </Tabs>
            <Box sx={{ marginTop: 4, marginBottom: 4 }}>
                <Grid container spacing={4}>
                    {transportData[tabValue === 0 ? 'Tradycyjny' : 'Eko'].map((transport, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card elevation={3} sx={{ marginBottom: 2 }}> {/* Added marginBottom here */}
                                <CardContent>
                                    <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                                        {transport.icon}
                                    </Box>
                                    <Typography variant="h6" align="center" gutterBottom>
                                        {transport.name}
                                    </Typography>
                                    <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
                                        {transport.emisjaCO2}
                                    </Typography>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel-content-${index}`}
                                            id={`panel-header-${index}`}
                                        >
                                            <Typography>Więcej informacji</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>{transport.details}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default TransportPubliczny;
