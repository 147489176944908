// AbsorbcjaResultsPage.js
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Typography, Card, CardContent, Box, Button, Paper } from '@mui/material';
import CO2Icon from '@mui/icons-material/Cloud';
import ForestIcon from '@mui/icons-material/Forest';
import ParkIcon from '@mui/icons-material/Park';
import SeedlingIcon from '@mui/icons-material/LocalFlorist';
import HomeIcon from "@mui/icons-material/Home";

// Updated formatNumber function with decimalPlaces parameter
const formatNumber = (input, decimalPlaces = 2) => {
    const num = Number(input);
    if (isNaN(num)) return '';
    if (num === 0) return '0';
    let numStr = num.toString();
    if (numStr.includes('e')) {
        numStr = num.toFixed(decimalPlaces); // Use decimalPlaces here
    }
    numStr = numStr.replace(/(\.\d*?[1-9])0+$/g, '$1');
    numStr = numStr.replace(/\.0+$/g, '');
    numStr = numStr.replace(new RegExp(`(\\.\\d{${decimalPlaces}})\\d+$`), '$1');
    return numStr;
};

const AbsorbcjaResultsPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { co2Emission, treesNeeded } = location.state || {};

    // Handle the case where state is not provided
    if (co2Emission === undefined || treesNeeded === undefined) {
        return (
            <Box mt={2} textAlign="center"> {/* Reduced mt from 4 to 2 */}
                <Typography variant="h6" color="error">
                    Brak danych wyników. Proszę wykonać obliczenia najpierw.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/absorbcja-co2')}
                    sx={{ mt: 1 }}
                >
                    Przejdź do Kalkulatora
                </Button>
            </Box>
        );
    }

    return (
        <Paper elevation={3} sx={{ padding: 2, maxWidth: '800px', margin: '0 auto' }}> {/* Reduced padding from 3 to 2 */}
            <Button
                variant="outlined"
                color="secondary"
                startIcon={<HomeIcon />}
                onClick={() => navigate('/')}
                fullWidth
                sx={{ mb: 2 }}
            >
                Powrót do menu głównego
            </Button>

            {/*<Typography variant="h4" sx={{ marginTop: 2, textAlign: 'center' }}>
                Wyniki Kalkulacji CO<sub>2</sub>
            </Typography>*/}

            <Box mt={3}> {/* Reduced mt from 4 to 3 */}
                <Typography variant="h6" gutterBottom>
                    <CO2Icon color="primary" /> Emisja CO<sub>2</sub>
                </Typography>
                <Card variant="outlined" sx={{ mb: 2 }}> {/* Reduced mb from 3 to 2 */}
                    <CardContent>
                        <Typography variant="body1">
                            Całkowita emisja CO<sub>2</sub>: <strong>{formatNumber(co2Emission)} kg</strong>
                        </Typography>
                    </CardContent>
                </Card>

                <Typography variant="h6" gutterBottom>
                    <ForestIcon color="success" /> Absorpcja CO<sub>2</sub> przez Drzewa
                </Typography>
                <Grid container spacing={1}> {/* Reduced spacing from 2 to 1 */}
                    <Grid item xs={12} sm={6}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="subtitle1">
                                    <ForestIcon /> Wieczyste Drzewa (100 lat)
                                </Typography>
                                <Typography variant="body2">
                                    {formatNumber(treesNeeded.hundredYearsOldTreeAbsorption)} drzew
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="subtitle1">
                                    <ForestIcon /> Drzewa 25-letnie
                                </Typography>
                                <Typography variant="body2">
                                    {formatNumber(treesNeeded.twentyFiveYearsOldTreeAbsorption)} drzew
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="subtitle1">
                                    <SeedlingIcon /> Sadzonki Drzew
                                </Typography>
                                <Typography variant="body2">
                                    {formatNumber(treesNeeded.seedlingTreeAbsorption)} drzew
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="subtitle1">
                                    <ParkIcon /> Absorpcja w Parkach
                                </Typography>
                                <Typography variant="body2">
                                    {formatNumber(treesNeeded.parkAbsorption, 6)} drzew {/* Set decimalPlaces to 6 */}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/* Przycisk Reset (Przelicz Ponownie) */}
                <Box mt={3} textAlign="center"> {/* Reduced mt from 4 to 3 */}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate('/absorbcja-co2')}
                        size="large"
                        sx={{ paddingX: 3, paddingY: 1 }}
                    >
                        Przelicz Ponownie
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
};

export default AbsorbcjaResultsPage;
