// src/components/CategorySlider.js

import React from 'react';
import {
    Slider,
    Typography,
    Paper,
    Grid,
    List,
    ListItem,
    ListItemText,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import categoriesData from '../data/categoriesData';
import PropTypes from 'prop-types';

const CategorySlider = ({ category, value, setValue, showPractices = true }) => {
    const getCategoryData = (category) => {
        // First, try to get categoryData from top-level
        let data = categoriesData[category];
        if (data) return data;

        // Then, try to get from 'Transport' subcategories
        if (categoriesData['Transport'] && categoriesData['Transport'].subcategories[category]) {
            return categoriesData['Transport'].subcategories[category];
        }

        // Could add more levels if needed
        return null;
    };

    const categoryData = getCategoryData(category);

    if (!categoryData) {
        console.error(`Category data not found for key: ${category}`);
        return <Typography color="error">No data available for this category.</Typography>;
    }

    const currentLevel = categoryData.levels.find(level => level.value === value);

    if (!currentLevel) {
        console.error(`Invalid level value: ${value} for category: ${category}`);
        return <Typography color="error">Invalid level selected.</Typography>;
    }

    const handleSelectChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <Paper elevation={0} style={{ margin: '0 auto', padding: '16px' }}>
            {category === 'Food Consumption' ? (
                <FormControl fullWidth>
                    <InputLabel id="food-consumption-label">Dieta</InputLabel>
                    <Select
                        labelId="food-consumption-label"
                        value={value}
                        label="Dieta"
                        onChange={handleSelectChange}
                    >
                        {categoryData.levels.map(level => (
                            <MenuItem key={level.value} value={level.value}>
                                {level.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            ) : (
                <Slider
                    track={false}
                    value={value}
                    onChange={(e, newValue) => setValue(newValue)}
                    step={1}
                    marks={categoryData.levels.map(level => ({ value: level.value, label: level.label }))}
                    min={1}
                    max={categoryData.levels.length}
                />
            )}

            {/* Conditionally display Good and Bad Practices */}
            {showPractices && (
                <Grid container spacing={2} style={{ marginTop: '16px' }}>
                    {/* Good Practices */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6">Dobre Praktyki</Typography>
                        <List dense>
                            {currentLevel.practices.good.map((practice, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={`• ${practice}`} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>

                    {/* Bad Practices */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6">Złe Praktyki</Typography>
                        <List dense>
                            {currentLevel.practices.bad.map((practice, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={`• ${practice}`} />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            )}
        </Paper>
    );
};

CategorySlider.propTypes = {
    category: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    setValue: PropTypes.func.isRequired,
    showPractices: PropTypes.bool,
};

export default CategorySlider;
