// AbsorbcjaCO2.js
import React, { useState, useEffect } from 'react';
import {
    Button,
    Paper,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Grid,
    CircularProgress,
    Alert
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Axios configuration with base URL and default headers
const api = axios.create({
    baseURL: 'https://api.nobexis.cc/',
    // headers: {
    //     "ngrok-skip-browser-warning": "69420"
    // }
});

const AbsorbcjaCO2 = () => {
    const navigate = useNavigate();

    // State variables for form inputs
    const [engineType, setEngineType] = useState('');
    const [engineCapacity, setEngineCapacity] = useState('');
    const [distance, setDistance] = useState('');
    const [maxDistance] = useState(10000); // Example max limit
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // State for engine capacity options
    const [capacityOptions, setCapacityOptions] = useState([]);

    // Define all possible engine capacities
    const allCapacityOptions = [
        { value: "1000", label: "1.0 L" },
        { value: "1200", label: "1.2 L" },
        { value: "1400", label: "1.4 L" },
        { value: "1600", label: "1.6 L" },
        { value: "1800", label: "1.8 L" },
        { value: "2000", label: "2.0 L" },
        { value: "3000", label: "2.0+ L" },
        // Add more options as needed
    ];

    // Effect to update capacity options based on engine type
    useEffect(() => {
        if (engineType === 'diesel') {
            // Filter out capacities below 1.8L for diesel
            const filtered = allCapacityOptions.filter(option => Number(option.value) >= 1800);
            setCapacityOptions(filtered);
        } else {
            // For other engine types, show all options or apply different filters if needed
            setCapacityOptions(allCapacityOptions);
        }
        // Reset engine capacity when engine type changes
        setEngineCapacity('');
    }, [engineType]);

    const handleCalculate = async () => {
        // Input validation
        if (!engineCapacity || !engineType || !distance) {
            setError('Proszę wypełnić wszystkie pola.');
            return;
        }

        const distanceNumber = Number(distance);
        const engineCapacityNumber = Number(engineCapacity); // Convert to number

        if (isNaN(distanceNumber) || distanceNumber < 0) {
            setError('Ilość kilometrów musi być liczbą dodatnią.');
            return;
        }

        if (isNaN(engineCapacityNumber) || engineCapacityNumber <= 0) {
            setError('Pojemność silnika musi być liczbą dodatnią.');
            return;
        }

        if (distanceNumber > maxDistance) {
            setError(`Ilość kilometrów nie może przekraczać ${maxDistance}.`);
            return;
        }

        setError('');
        setLoading(true);

        try {
            const params = {
                kilometers: distanceNumber,
                engineType,
                engineCapacity: engineCapacityNumber // Send as number
            };

            // Make both API calls in parallel
            const [emissionResponse, absorptionResponse] = await Promise.all([
                api.get('/average-car-emission/distance', { params }),
                api.get('/tree-absorption', { params })
            ]);

            // Handle emissionResponse
            const emissionData = emissionResponse.data; // Expected to be a number
            if (typeof emissionData !== 'number') {
                throw new Error('Nieprawidłowy format emisji CO₂.');
            }

            // Handle absorptionResponse
            const absorptionData = absorptionResponse.data; // Expected to be an array of four numbers
            if (!Array.isArray(absorptionData) || absorptionData.length !== 4) {
                throw new Error('Nieprawidłowy format danych absorpcji drzew.');
            }

            const [hundredYearsOldTreeAbsorption, twentyFiveYearsOldTreeAbsorption, seedlingTreeAbsorption, parkAbsorption] = absorptionData;

            // Validate each absorption value
            if (
                typeof hundredYearsOldTreeAbsorption !== 'number' ||
                typeof twentyFiveYearsOldTreeAbsorption !== 'number' ||
                typeof seedlingTreeAbsorption !== 'number' ||
                typeof parkAbsorption !== 'number'
            ) {
                throw new Error('Nieprawidłowe wartości absorpcji drzew.');
            }

            // Prepare the data to pass to the results page
            const resultsData = {
                co2Emission: emissionData,
                treesNeeded: {
                    hundredYearsOldTreeAbsorption,
                    twentyFiveYearsOldTreeAbsorption,
                    seedlingTreeAbsorption,
                    parkAbsorption
                }
            };

            // Navigate do the results page with the data
            navigate('/results', { state: resultsData });

        } catch (err) {
            console.error(err);
            if (err.response) {
                setError(`Błąd: ${err.response.data.message || 'Nieznany błąd serwera.'}`);
            } else if (err.request) {
                setError('Brak odpowiedzi od serwera. Sprawdź połączenie internetowe.');
            } else {
                setError(`Błąd: ${err.message}`);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleReset = () => {
        setEngineType('');
        setEngineCapacity('');
        setDistance('');
        setError('');
    };

    return (
        <Paper elevation={3} sx={{ padding: 3, maxWidth: '600px', margin: '0 auto' }}>
            <Button variant="outlined" color="secondary" onClick={() => navigate('/')}>
                Powrót do menu głównego
            </Button>

            <Typography variant="h5" sx={{ marginTop: 2, textAlign: 'center' }}>
                Kalkulator CO<sub>2</sub> wyprodukowanego podczas twojej dziennej podróży
            </Typography>

            <form noValidate autoComplete="off" style={{ marginTop: '30px' }}>
                <Grid container spacing={3}>
                    {/* Engine Type */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="engine-type-label">Rodzaj Silnika</InputLabel>
                            <Select
                                labelId="engine-type-label"
                                id="engine-type"
                                value={engineType}
                                label="Rodzaj Silnika"
                                onChange={(e) => setEngineType(e.target.value)}
                            >
                                <MenuItem value="diesel">Diesel</MenuItem>
                                <MenuItem value="petrol">Benzyna</MenuItem>
                                {/* Add more options as needed */}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Engine Capacity */}
                    <Grid item xs={12}>
                        <FormControl fullWidth disabled={!engineType}>
                            <InputLabel id="engine-capacity-label">Pojemność Silnika</InputLabel>
                            <Select
                                labelId="engine-capacity-label"
                                id="engine-capacity"
                                value={engineCapacity}
                                label="Pojemność Silnika"
                                onChange={(e) => setEngineCapacity(e.target.value)}
                            >
                                {capacityOptions.map(option => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {!engineType && (
                            <Typography variant="caption" color="textSecondary">
                                Wybierz najpierw rodzaj silnika
                            </Typography>
                        )}
                    </Grid>

                    {/* Distance */}
                    <Grid item xs={12}>
                        <TextField
                            label="Ilość kilometrów"
                            type="number"
                            fullWidth
                            InputProps={{ inputProps: { max: maxDistance, min: 0 } }}
                            value={distance}
                            onChange={(e) => setDistance(e.target.value)}
                            helperText={`Maksymalnie ${maxDistance} km`}
                        />
                    </Grid>

                    {/* Submit Button */}
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCalculate}
                            disabled={loading}
                            fullWidth
                        >
                            Oblicz CO<sub>2</sub>
                        </Button>
                    </Grid>

                    {/* Loading Indicator */}
                    {loading && (
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <CircularProgress />
                        </Grid>
                    )}

                    {/* Error Message */}
                    {error && (
                        <Grid item xs={12}>
                            <Alert severity="error">{error}</Alert>
                        </Grid>
                    )}
                </Grid>
            </form>
        </Paper>
    );

};

export default AbsorbcjaCO2;
