// ImprezyMasowe.js

import React, { useState } from 'react';
import {
    Tabs,
    Tab,
    Box,
    Grid,
    Card,
    CardContent,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
} from '@mui/material';
import { IoIosBusiness } from 'react-icons/io';
import { FaRecycle, FaWind } from 'react-icons/fa';
import { MdOutlineBuild } from 'react-icons/md';
import { AiOutlineCar } from 'react-icons/ai';
import { BsBuilding } from 'react-icons/bs';

const ImprezyMasowe = () => {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleBackButtonClick = () => {
        window.history.back();
    };

    const eventData = {
        Kultura: [
            {
                name: 'Festyny',
                icon: <IoIosBusiness fontSize="large" color="primary" />,
                emisjaCO2: 'Emisja CO2 dziennie: 1500 kg',
                details: 'Festyny generują emisje przez transport i użycie energii w trakcie wydarzenia.',
            },
            {
                name: 'Koncerty',
                icon: <FaWind fontSize="large" color="primary" />,
                emisjaCO2: 'Emisja CO2 dziennie: 2000 kg',
                details: 'Duże wydarzenia muzyczne wymagają znacznych zasobów energii, co generuje CO2.',
            },
            {
                name: 'Targi',
                icon: <MdOutlineBuild fontSize="large" color="primary" />,
                emisjaCO2: 'Emisja CO2 dziennie: 1800 kg',
                details: 'Targi często wiążą się z transportem towarów oraz energią elektryczną do oświetlenia.',
            },
        ],
        Sport: [
            {
                name: 'Wydarzenia Sportowe',
                icon: <AiOutlineCar fontSize="large" color="success" />,
                emisjaCO2: 'Emisja CO2 dziennie: 2500 kg',
                details: 'Wydarzenia sportowe generują emisje przez transport fanów i organizację infrastruktury.',
            },
            {
                name: 'Maratony',
                icon: <FaRecycle fontSize="large" color="success" />,
                emisjaCO2: 'Emisja CO2 dziennie: 800 kg',
                details: 'Maratony mają niższą emisję w porównaniu do innych wydarzeń dzięki ich organizacji.',
            },
        ],
    };

    return (
        <Box sx={{ padding: 4 }}>
            <Button variant="outlined" onClick={handleBackButtonClick} sx={{ mb: 2 }}>
                Wróć
            </Button>
            <Typography variant="h4" gutterBottom align="center">
                Imprezy Masowe
            </Typography>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                centered
                textColor="primary"
                indicatorColor="primary"
            >
                <Tab label="Kultura" />
                <Tab label="Sport" />
            </Tabs>
            <Box sx={{ marginTop: 4, marginBottom: 4 }}>
                <Grid container spacing={4}>
                    {eventData[tabValue === 0 ? 'Kultura' : 'Sport'].map((event, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card elevation={3} sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                                        {event.icon}
                                    </Box>
                                    <Typography variant="h6" align="center" gutterBottom>
                                        {event.name}
                                    </Typography>
                                    <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
                                        {event.emisjaCO2}
                                    </Typography>
                                    <Accordion>
                                        <AccordionSummary
                                            aria-controls={`panel-content-${index}`}
                                            id={`panel-header-${index}`}
                                        >
                                            <Typography>Więcej informacji</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>{event.details}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default ImprezyMasowe;
