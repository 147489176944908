// src/components/TransportSliders.js

import React, { useEffect } from 'react';
import {
    Typography,
    Grid,
    Paper,
    Pagination
} from '@mui/material';
import PropTypes from 'prop-types';
import categoriesData from '../data/categoriesData';
import CategorySlider from './CategorySlider';

const TransportSliders = ({
                              setTransportValues,
                              currentPage,
                              setCurrentPage,
                              totalPages,
                              onTotalPagesChange
                          }) => {
    const transportData = categoriesData['Transport'];
    const vehicles = Object.keys(transportData.subcategories);

    // Number of items per page
    const ITEMS_PER_PAGE = 2;

    // Calculate the indices for the current page
    const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
    const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
    const currentVehicles = vehicles.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages once
    useEffect(() => {
        const calculatedTotalPages = Math.ceil(vehicles.length / ITEMS_PER_PAGE);
        if (calculatedTotalPages !== totalPages) {
            onTotalPagesChange(calculatedTotalPages);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vehicles.length]);

    // Initialize transport slider values to the average level (3) only once
    const initialValues = React.useMemo(() => {
        return vehicles.reduce((acc, vehicle) => {
            acc[vehicle] = 3;
            return acc;
        }, {});
    }, [vehicles]);

    const [values, setValues] = React.useState(initialValues);

    const handleChange = (vehicle, newValue) => {
        setValues(prev => ({
            ...prev,
            [vehicle]: newValue
        }));
    };

    // Pass the transport values up to the parent component
    useEffect(() => {
        setTransportValues(values);
    }, [values, setTransportValues]);

    return (
        <Paper elevation={0} style={{ margin: '10px', padding: '16px', marginBottom: '16px' }}>
            <Typography gutterBottom>
                Ustaw poziom konsumpcji dla każdego środka transportu:
            </Typography>
            <Grid container spacing={4}>
                {currentVehicles.map((vehicle) => {
                    const vehicleData = transportData.subcategories[vehicle];

                    if (!vehicleData || !vehicleData.levels) {
                        return (
                            <Grid item xs={12} md={6} key={vehicle}>
                                <Typography variant="h6">{vehicle}</Typography>
                                <Typography>No data available for this category.</Typography>
                            </Grid>
                        );
                    }

                    return (
                        <Grid item xs={12} md={6} key={vehicle}>
                            <Typography variant="h6">{vehicleData.name}</Typography>
                            <CategorySlider
                                category={vehicle}
                                value={values[vehicle]}
                                setValue={(newValue) => handleChange(vehicle, newValue)}
                                showPractices={false}
                            />
                        </Grid>
                    );
                })}
            </Grid>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="primary"
                    showFirstButton
                    showLastButton
                />
            </div>
        </Paper>
    );
};

TransportSliders.propTypes = {
    setTransportValues: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired,
    onTotalPagesChange: PropTypes.func.isRequired,
};

export default TransportSliders;
