import React, { useEffect, useState } from 'react';
import {
    Typography,
    Button,
    Container,
    Paper,
    Box,
    Stack,
} from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FactoryIcon from '@mui/icons-material/Factory';
import CloudIcon from '@mui/icons-material/Cloud';
import { green, orange, red } from '@mui/material/colors';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const MainMenu = () => {
    const [co2Status, setCo2Status] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const fetchAirQualityData = async () => {
        try {
            const response = await axios.get('https://api.nobexis.cc/air-quality');
            const co2Level = response.data;
            setCo2Status(co2Level);
        } catch (err) {
            setError('Failed to fetch data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAirQualityData();
    }, []);

    const co2StatusMessage = loading
        ? 'Ładowanie...'
        : co2Status > 300
            ? 'Wysoki'
            : co2Status > 150
                ? 'Umiarkowany'
                : 'Niski';

    const indicatorColor = loading
        ? 'grey'
        : co2Status > 300
            ? '#E76F51'
            : co2Status > 150
                ? '#F4A261'
                : '#2A9D8F';

    // Handler functions for navigation
    const handleMojaEmisja = () => {
        navigate('/moja-emisja');
    };

    const handleEmisjaMiasta = () => {
        navigate('/emisja-miasta');
    };

    const handleAbsorpcjaCO2 = () => {
        navigate('/absorbcja-co2');
    };

    return (
        <Box
            sx={{
                background: 'url("background.webp") -70px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Container sx={{ width: '100%', maxWidth: 400 }}>
                <Box
                    sx={{
                        backgroundColor: 'white',
                        padding: 3,
                        borderRadius: 2,
                        boxShadow: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                        Poziom CO₂ w Krakowie
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Box
                            sx={{
                                width: 14,
                                height: 14,
                                bgcolor: indicatorColor,
                                borderRadius: '50%',
                                mr: 1,
                            }}
                        />
                        <Typography variant="body1" sx={{ color: indicatorColor, fontSize: '18px' }}>
                            {loading ? '...' : `${co2Status} µg/m³ - ${co2StatusMessage}`}
                        </Typography>
                    </Box>
                    {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
                    <Paper elevation={0} sx={{ padding: '0 24px 24px 24px', width: '100%', borderRadius: 2 }}>
                        <Stack spacing={2} direction="column" alignItems="center" sx={{ mt: 2 }}>
                            <Button
                                variant="contained"
                                startIcon={<DirectionsCarIcon />}
                                onClick={handleMojaEmisja}
                                fullWidth
                                sx={{ maxWidth: 300, backgroundColor: '#264653'}}
                            >
                                Moja emisja CO₂
                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<FactoryIcon />}
                                onClick={handleEmisjaMiasta}
                                fullWidth
                                sx={{ maxWidth: 300, backgroundColor: '#264653'}}
                            >
                                Emisja miasta
                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<CloudIcon />}
                                onClick={handleAbsorpcjaCO2}
                                fullWidth
                                sx={{ maxWidth: 300, backgroundColor: '#264653'}}
                            >
                                Absorpcja CO₂
                            </Button>
                        </Stack>
                    </Paper>
                </Box>
            </Container>
        </Box>
    );
};

export default MainMenu;
