// src/components/ProgressForm.js

import React, { useState, useEffect } from 'react';
import { Button, Paper, Typography, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import CategorySlider from './CategorySlider';
import TransportSliders from './TransportSliders';
import Result from './Result';
import categoriesData from '../data/categoriesData';

const ProgressForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const resetKey = state?.resetKey;

    const categories = Object.keys(categoriesData); // ['Electricity', 'Water', 'Trash', 'Food Consumption', 'Transport']

    // Initialize all slider values to the middle
    const initialValues = categories.reduce((acc, category) => {
        if (category === 'Transport') {
            acc[category] = {}; // Will be handled separately
        } else {
            acc[category] = 3; // Assuming 3 is the average level
        }
        return acc;
    }, {});

    const [values, setValues] = useState(initialValues);
    const [transportValues, setTransportValues] = useState({});
    const [currentStep, setCurrentStep] = useState(0);

    // Pagination state for TransportSliders
    const [transportCurrentPage, setTransportCurrentPage] = useState(1);
    const [transportTotalPages, setTransportTotalPages] = useState(1);

    useEffect(() => {
        if (resetKey) {
            // Reset form state
            setValues(initialValues);
            setTransportValues({});
            setCurrentStep(0);
            setTransportCurrentPage(1);
            setTransportTotalPages(1);
        }
    }, [resetKey, initialValues]);

    const handleNext = () => {
        const currentCategory = categories[currentStep];

        if (currentCategory !== 'Transport') {
            // For non-Transport categories, simply move to the next step
            setCurrentStep(prev => prev + 1);
        } else {
            // For Transport category, handle pagination
            if (transportCurrentPage < transportTotalPages) {
                setTransportCurrentPage(prev => prev + 1);
            } else {
                // If on the last page, proceed to Result
                setCurrentStep(prev => prev + 1);
            }
        }
    };

    const handleBack = () => {
        if (currentStep === 0) return;

        const previousCategory = categories[currentStep - 1];

        if (previousCategory !== 'Transport') {
            setCurrentStep(prev => prev - 1);
        } else {
            // If going back to Transport, reset to the first page
            setCurrentStep(prev => prev - 1);
            setTransportCurrentPage(1);
        }
    };

    const handleFinish = () => {
        // Since Result is rendered within ProgressForm, no navigation is needed
        setCurrentStep(categories.length);
    };

    const setValueForCurrentStep = (value) => {
        const newValues = { ...values };
        const category = categories[currentStep];
        newValues[category] = value;
        setValues(newValues);
    };

    // Handle Transport Values
    const handleSetTransportValues = (newTransportValues) => {
        setTransportValues(newTransportValues);
    };

    // Callback to receive total pages from TransportSliders
    const handleTransportTotalPagesChange = (calculatedTotalPages) => {
        setTransportTotalPages(calculatedTotalPages);
    };

    return (
        <Box
            display="flex"
            minHeight="100vh"
            bgcolor="#f5f5f5"
        >
            <Paper
                elevation={3}
                sx={{
                    padding: { xs: 2, sm: 4, md: 6 },
                    width: '100%',
                    maxWidth: '800px',
                    margin: '0 auto',
                }}
            >
                {/* Conditionally render the Back to Main Menu button */}
                {currentStep < categories.length && (
                    <Box display="flex" justifyContent="flex-start">
                        <Button variant="outlined" color="secondary" onClick={() => navigate('/')}>
                            Powrót do menu głównego
                        </Button>
                    </Box>
                )}

                {currentStep < categories.length ? (
                    // Removed the top margin by setting mt to 0
                    <Box mt={0} sx={{ marginTop: 2, textAlign: 'center' }}>
                        <Typography variant="h5" gutterBottom align="center">
                            {categoriesData[categories[currentStep]].name}
                        </Typography>
                        <Box mt={2}>
                            {categories[currentStep] === 'Transport' ? (
                                <TransportSliders
                                    setTransportValues={handleSetTransportValues}
                                    currentPage={transportCurrentPage}
                                    setCurrentPage={setTransportCurrentPage}
                                    totalPages={transportTotalPages}
                                    onTotalPagesChange={handleTransportTotalPagesChange}
                                />
                            ) : (
                                <CategorySlider
                                    category={categories[currentStep]} // Pass the key string
                                    value={values[categories[currentStep]]}
                                    setValue={setValueForCurrentStep}
                                />
                            )}
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            mt={4}
                            flexDirection={{ xs: 'column', sm: 'row' }}
                            gap={2}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleBack}
                                disabled={currentStep === 0}
                                fullWidth={{ xs: true, sm: false }}
                            >
                                Wstecz
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={currentStep === categories.length - 1 && transportCurrentPage === transportTotalPages ? handleFinish : handleNext}
                                fullWidth={{ xs: true, sm: false }}
                            >
                                {currentStep === categories.length - 1 && transportCurrentPage === transportTotalPages ? 'Zakończ' : 'Dalej'}
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <Result
                        values={values}
                        transportValues={transportValues}
                        categories={categories}
                        categoriesData={categoriesData}
                    />
                )}
            </Paper>
        </Box>
    );
};

export default ProgressForm;
