// src/components/Result.js

import React from 'react';
import {
    Typography,
    Button,
    Card,
    CardContent,
    Grid,
    Avatar,
    LinearProgress,
    Box,
} from '@mui/material';
import { green, yellow, red } from '@mui/material/colors';
import NatureIcon from '@mui/icons-material/Nature'; // Updated icon import
import WarningIcon from '@mui/icons-material/Warning';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import categoriesData from '../data/categoriesData';

const Result = ({ values, transportValues, categories }) => {
    const navigate = useNavigate();

    // Function to calculate emissions for a non-Transport category
    const calculateCategoryEmissions = (categoryKey, selectedValue) => {
        const category = categoriesData[categoryKey];
        if (!category || !category.levels) return 0;

        const level = category.levels.find(lvl => lvl.value === selectedValue);
        if (!level) return 0;

        return level.emissions;
    };

    // Function to calculate emissions for Transport
    const calculateTransportEmissions = () => {
        const transport = categoriesData['Transport'];
        if (!transport || !transport.subcategories) return 0;

        let total = 0;
        for (const vehicle in transport.subcategories) {
            const vehicleData = transport.subcategories[vehicle];
            const selectedLevel = transportValues[vehicle] || 3; // Default to average if not set
            const levelData = vehicleData.levels.find(level => level.value === selectedLevel);
            if (levelData) {
                // Multiply the average emissions by the emission multiplier
                total += vehicleData.averageEmissions * levelData.emissions;
            }
        }
        return total;
    };

    // Calculate total emissions
    let totalEmissions = 0;
    categories.forEach(category => {
        if (category !== 'Transport') {
            totalEmissions += calculateCategoryEmissions(category, values[category]);
        }
    });
    totalEmissions += calculateTransportEmissions();

    // Determine eco-friendly status based on total emissions
    const getEcoStatus = (emissions) => {
        if (emissions < 3000) {
            return {
                label: 'Bardzo Przyjazny dla Ziemi',
                color: green[500],
                icon: <NatureIcon />
            };
        } else if (emissions < 5000) {
            return {
                label: 'Średnio Przyjazny dla Ziemi',
                color: yellow[700],
                icon: <SentimentSatisfiedIcon />
            };
        } else {
            return {
                label: 'Niezbyt Przyjazny dla Ziemi',
                color: red[500],
                icon: <WarningIcon />
            };
        }
    };

    const ecoStatus = getEcoStatus(totalEmissions);

    // Updated maximum emissions value
    const maxEmissions = 6200;
    const emissionPercentage = (totalEmissions / maxEmissions) * 100;

    return (
        <Card elevation={3} style={{ padding: '24px', marginBottom: '24px' }}>
            <CardContent>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={8}>
                        <Typography variant="h4" gutterBottom>
                            Twój całkowity ślad CO₂
                        </Typography>
                        <Typography variant="h6" color="textSecondary">
                            {totalEmissions.toFixed(2)} kg CO₂ rocznie
                        </Typography>

                        <Box sx={{ width: '100%', mt: 2 }}>
                            <LinearProgress
                                variant="determinate"
                                value={emissionPercentage > 100 ? 100 : emissionPercentage}
                                color="secondary"
                                style={{ height: '10px', borderRadius: '5px' }}
                            />
                            <Typography variant="body2" color="textSecondary" align="right">
                                {emissionPercentage.toFixed(1)}% of {maxEmissions} kg
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                        <Avatar
                            style={{
                                backgroundColor: ecoStatus.color,
                                width: 80,
                                height: 80,
                                margin: '0 auto'
                            }}
                        >
                            {ecoStatus.icon}
                        </Avatar>
                        <Typography variant="h6" style={{ marginTop: '8px', color: ecoStatus.color }}>
                            {ecoStatus.label}
                        </Typography>
                    </Grid>
                </Grid>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/')}
                    style={{ marginTop: '24px' }}
                >
                    Zakończ i wróć do głównego menu
                </Button>
            </CardContent>
        </Card>
    );
};

Result.propTypes = {
    values: PropTypes.object.isRequired,
    transportValues: PropTypes.object.isRequired,
    categories: PropTypes.array.isRequired,
    // Removed categoriesData from propTypes as it's imported directly
};

export default Result;
