// EliminacjaCO2.js

import React from 'react';
import {
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Link,
    Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TramIcon from '@mui/icons-material/Tram';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import ParkIcon from '@mui/icons-material/Park';

const EliminacjaCO2 = () => {
    const projects = [
        {
            name: 'Tramwaje na Zielone Torowiska',
            icon: <TramIcon fontSize="large" color="success" />,
            description: 'Implementacja zielonych torowisk tramwajowych w Krakowie.',
            emisjaDziennie: 'Emisja CO₂ dziennie: 600 kg',
            emisjaRocznie: 'Emisja CO₂ rocznie: 219,000 kg',
            details:
                'Zielone torowiska tramwajowe absorbują CO₂, redukują hałas i poprawiają jakość powietrza. Dzięki roślinności wokół torów zmniejszają negatywny wpływ na ekosystem oraz miejską infrastrukturę, jednocześnie wspierając estetykę miasta.',
            image: 'https://via.placeholder.com/400x200.png?text=Tramwaje+na+Zielone+Torowiska',
        },
        {
            name: 'Autobusy niskoemisyjne',
            icon: <DirectionsBusIcon fontSize="large" color="success" />,
            description: 'Wprowadzenie autobusów niskoemisyjnych w Krakowie dla zrównoważonego rozwoju miasta.',
            emisjaDziennie: 'Emisja CO₂ dziennie: 500 kg',
            emisjaRocznie: 'Emisja CO₂ rocznie: 182,500 kg',
            details:
                'Autobusy niskoemisyjne, zarówno elektryczne jak i wodorowe, znacząco redukują emisję CO₂ oraz inne zanieczyszczenia powietrza. Elektryczne autobusy charakteryzują się wysoką sprawnością i niższymi kosztami utrzymania, podczas gdy wodorowe oferują dłuższy zasięg i krótszy czas tankowania. Oba typy przyczyniają się do zrównoważonego rozwoju miasta poprzez redukcję śladu węglowego oraz poprawę jakości życia mieszkańców.',
            image: 'https://via.placeholder.com/400x200.png?text=Autobusy+niskoemisyjne',
        },
        {
            name: 'Program Sadzenia Drzew',
            icon: <ParkIcon fontSize="large" color="success" />,
            description: 'Sadzenie drzew w całym mieście.',
            emisjaDziennie: 'Emisja CO₂ dziennie: 800 kg',
            emisjaRocznie: 'Emisja CO₂ rocznie: 292,000 kg',
            details:
                'Drzewa absorbują CO₂ z atmosfery, przyczyniając się do redukcji gazów cieplarnianych oraz poprawy jakości powietrza. Program ten również zwiększa estetykę miasta i wspiera bioróżnorodność.',
            image: 'https://via.placeholder.com/400x200.png?text=Program+Sadzenia+Drzew',
        },
    ];

    const handleBackButtonClick = () => {
        window.history.back();
    };

    return (
        <Box sx={{ padding: 4 }}>
            <Button variant="outlined" onClick={handleBackButtonClick} sx={{ mb: 2 }}>
                Wróć
            </Button>
            <Typography variant="h4" gutterBottom align="center">
                Projekty Eliminacji CO₂ w Krakowie
            </Typography>
            <Typography variant="subtitle1" gutterBottom align="center" sx={{ marginBottom: 4 }}>
                Najnowsze informacje znajdziesz na stronie{' '}
                <Link href="https://krakow.eco" target="_blank" rel="noopener" underline="hover">
                    krakow.eco
                </Link>.
            </Typography>
            <Grid container spacing={4}>
                {projects.map((project, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card elevation={3} sx={{ marginBottom: 2 }}>
                            {project.image && (
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={project.image}
                                    alt={project.name}
                                />
                            )}
                            <Box display="flex" justifyContent="center" alignItems="center" paddingTop={2}>
                                {project.icon}
                            </Box>
                            <CardContent>
                                <Typography variant="h6" gutterBottom align="center">
                                    {project.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" gutterBottom align="center">
                                    {project.description}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" align="center">
                                    {project.emisjaDziennie}
                                </Typography>
                                <Typography variant="body2" color="textSecondary" align="center">
                                    {project.emisjaRocznie}
                                </Typography>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel-content-${index}`}
                                        id={`panel-header-${index}`}
                                    >
                                        <Typography>Więcej informacji</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>{project.details}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Box sx={{ marginBottom: 4 }} /> {/* Optional: Add an empty Box for additional spacing */}
        </Box>
    );
};

export default EliminacjaCO2;
