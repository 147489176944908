// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet
import MainMenu from './components/MainMenu';
import ProgressForm from './components/ProgressForm';
import EmisjaMiasta from "./components/EmisjaMiasta";
import TransportPubliczny from "./components/TransportPubliczny";
import SektorPrzemyslowy from "./components/SektorPrzemyslowy";
import ImprezyMasowe from "./components/ImprezyMasowe";
import EliminacjaCO2 from "./components/EliminacjaCO2";
import AbsorbcjaCO2 from "./components/AbsorbcjaC02";
import AbsorbcjaResultsPage from "./components/AbsorbcjaResultsPage";
// import NotFound from "./components/NotFound"; // If you've added a NotFound component

const App = () => {
    return (
        <>
            <Helmet>
                {/* Basic Meta Tags */}
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="description" content="A CO2 consumption calculator" />

                {/* Enable Standalone Mode */}
                <meta name="apple-mobile-web-app-capable" content="yes" />

                {/* Set the Status Bar Style */}
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />

                {/* Define the App Title */}
                <meta name="apple-mobile-web-app-title" content="CO2unter - Kraków" />

                {/* Favicon */}
                <link rel="icon" href={`${process.env.PUBLIC_URL}/favicon.ico`} />

                {/* Web App Manifest */}
                <link rel="manifest" href={`${process.env.PUBLIC_URL}/manifest.json`} />

                {/* Title */}
                <title>CO2unter - Kraków</title>

                {/* Stylesheet */}
                <link rel="stylesheet" href={`${process.env.PUBLIC_URL}/styles.css`} />
            </Helmet>
            <Router>
                <Routes>
                    <Route path="/" element={<MainMenu />} />
                    <Route path="/moja-emisja" element={<ProgressForm />} />
                    <Route path="/emisja-miasta" element={<EmisjaMiasta />} />
                    <Route path="/transport" element={<TransportPubliczny />} />
                    <Route path="/sektor-przemyslowy" element={<SektorPrzemyslowy />} />
                    <Route path="/imprezy-masowe" element={<ImprezyMasowe />} />
                    <Route path="/eliminacja-co2" element={<EliminacjaCO2 />} />
                    <Route path="/absorbcja-co2" element={<AbsorbcjaCO2 />} />
                    <Route path="/results" element={<AbsorbcjaResultsPage />} />
                    {/*<Route path="*" element={<NotFound />} /> {/* Catch-all route */}
                </Routes>
            </Router>
        </>
    );
};

export default App;
