// SektorPrzemyslowy.js

import React, { useState } from 'react';
import {
    Tabs,
    Tab,
    Box,
    Grid,
    Card,
    CardContent,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
} from '@mui/material';
import { IoIosBusiness } from 'react-icons/io';
import { FaRecycle, FaWind } from 'react-icons/fa';
import { MdOutlineBuild } from 'react-icons/md';
import { AiOutlineCar } from 'react-icons/ai';
import { BsBuilding } from 'react-icons/bs';

const SektorPrzemyslowy = () => {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleBackButtonClick = () => {
        window.history.back();
    };

    const industryData = {
        Tradycyjny: [
            {
                name: 'Przemysł Wydobywczy',
                icon: <BsBuilding fontSize="large" color="primary" />,
                emisjaCO2: 'Emisja CO2 dziennie: 4000 kg',
                details: 'Przemysł wydobywczy generuje znaczne ilości CO2, głównie przez spalanie paliw kopalnych.',
            },
            {
                name: 'Przemysł Chemiczny',
                icon: <FaRecycle fontSize="large" color="primary" />,
                emisjaCO2: 'Emisja CO2 dziennie: 3000 kg',
                details: 'Produkcja chemikaliów jest intensywna pod względem energii, co prowadzi do wysokiej emisji CO2.',
            },
            {
                name: 'Przemysł Metalurgiczny',
                icon: <MdOutlineBuild fontSize="large" color="primary" />,
                emisjaCO2: 'Emisja CO2 dziennie: 5000 kg',
                details: 'Produkcja stali i metali emituje duże ilości CO2 w procesie przetwarzania.',
            },
            {
                name: 'Logistyka i Transport',
                icon: <AiOutlineCar fontSize="large" color="primary" />,
                emisjaCO2: 'Emisja CO2 dziennie: 2500 kg',
                details: 'Transport towarów generuje znaczne emisje, w tym przez samochody ciężarowe i statki.',
            },
        ],
        Eko: [
            {
                name: 'Przemysł Odnawialnych Źródeł Energii',
                icon: <FaWind fontSize="large" color="success" />,
                emisjaCO2: 'Emisja CO2 dziennie: 0 kg',
                details: 'Produkcja energii ze źródeł odnawialnych nie emituje CO2.',
            },
            {
                name: 'Recykling',
                icon: <FaRecycle fontSize="large" color="success" />,
                emisjaCO2: 'Emisja CO2 dziennie: 50 kg',
                details: 'Recykling materiałów zmniejsza emisje związane z produkcją nowych materiałów.',
            },
            {
                name: 'Zielone Technologie',
                icon: <FaWind fontSize="large" color="success" />,
                emisjaCO2: 'Emisja CO2 dziennie: 100 kg',
                details: 'Zastosowanie zielonych technologii minimalizuje emisje w produkcji.',
            },
        ],
    };

    return (
        <Box sx={{ padding: 4 }}>
            <Button variant="outlined" onClick={handleBackButtonClick} sx={{ mb: 2 }}>
                Wróć
            </Button>
            <Typography variant="h4" gutterBottom align="center">
                Sektor Przemysłowy
            </Typography>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                centered
                textColor="primary"
                indicatorColor="primary"
            >
                <Tab label="Tradycyjny" />
                <Tab label="Eko" />
            </Tabs>
            <Box sx={{ marginTop: 4, marginBottom: 4 }}>
                <Grid container spacing={4}>
                    {industryData[tabValue === 0 ? 'Tradycyjny' : 'Eko'].map((industry, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card elevation={3} sx={{ marginBottom: 2 }}>
                                <CardContent>
                                    <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                                        {industry.icon}
                                    </Box>
                                    <Typography variant="h6" align="center" gutterBottom>
                                        {industry.name}
                                    </Typography>
                                    <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
                                        {industry.emisjaCO2}
                                    </Typography>
                                    <Accordion>
                                        <AccordionSummary
                                            aria-controls={`panel-content-${index}`}
                                            id={`panel-header-${index}`}
                                        >
                                            <Typography>Więcej informacji</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>{industry.details}</Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default SektorPrzemyslowy;
