// EmisjaMiasta.js
import React from 'react';
import { Button, Paper, Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FactoryIcon from '@mui/icons-material/Factory';
import PeopleIcon from '@mui/icons-material/People';
import NatureIcon from '@mui/icons-material/Nature'; // Replaced EcoIcon with NatureIcon
import HomeIcon from '@mui/icons-material/Home';

const EmisjaMiasta = () => {
    const navigate = useNavigate();

    // Handler functions for each button
    const handleTransport = () => {
        navigate('/transport');
    };

    const handleSektorPrzemyslowy = () => {
        navigate('/sektor-przemyslowy');
    };

    const handleImprezyMasowe = () => {
        navigate('/imprezy-masowe');
    };

    const handleEliminacjaCO2 = () => {
        navigate('/eliminacja-co2');
    };

    const handlePowrotDoMenu = () => {
        navigate('/');
    };

    return (
        <Paper elevation={3} style={{ padding: '24px', textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom>
                Emisja Miasta
            </Typography>
            <Stack spacing={2} direction="column" alignItems="center" style={{ marginTop: '20px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DirectionsCarIcon />}
                    onClick={handleTransport}
                    fullWidth
                >
                    Transport Publiczny
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FactoryIcon />}
                    onClick={handleSektorPrzemyslowy}
                    fullWidth
                >
                    Sektor przemysłowy
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PeopleIcon />}
                    onClick={handleImprezyMasowe}
                    fullWidth
                >
                    Imprezy masowe
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<NatureIcon />} // Updated icon
                    onClick={handleEliminacjaCO2}
                    fullWidth
                >
                    Eliminacja CO2
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<HomeIcon />}
                    onClick={handlePowrotDoMenu}
                    fullWidth
                >
                    Powrót do menu głównego
                </Button>
            </Stack>
        </Paper>
    );
};

export default EmisjaMiasta;
