// src/data/categoriesData.js

const categoriesData = {
    'Electricity': {
        name: 'Zużycie prądu',
        levels: [
            {
                value: 1,
                label: 'Mało',
                practices: {
                    good: [
                        'Używanie energooszczędnych urządzeń, np. żarówki LED',
                        'Wyłączanie urządzeń z gniazdka, aby uniknąć "standby energy"'
                    ],
                    bad: [
                        'Rezygnacja z niezbędnych urządzeń, co może obniżyć komfort życia',
                        'Zbyt surowe ograniczenia w użyciu energii mogą prowadzić do dyskomfortu zdrowotnego'
                    ]
                },
                emissions: 10
            },
            {
                value: 2,
                label: '',
                practices: {
                    good: [
                        'Wybieranie urządzeń o niskim zużyciu energii',
                        'Ograniczenie korzystania z urządzeń w czasie szczytowego obciążenia sieci'
                    ],
                    bad: [
                        'Korzystanie z energochłonnych urządzeń okazjonalnie, co może dawać fałszywe poczucie oszczędności',
                        'Niedbałość o wyłączanie urządzeń, które zużywają mało energii'
                    ]
                },
                emissions: 40
            },
            {
                value: 3,
                label: 'Średnio',
                practices: {
                    good: [
                        'Stosowanie programów oszczędzania energii w urządzeniach AGD',
                        'Ograniczanie korzystania z energochłonnych urządzeń'
                    ],
                    bad: [
                        'Częste korzystanie z klimatyzacji lub ogrzewania bez odpowiedniej izolacji',
                        'Brak optymalizacji pracy urządzeń, np. zbyt wysokie temperatury w lodówce'
                    ]
                },
                emissions: 100
            },
            {
                value: 4,
                label: '',
                practices: {
                    good: [
                        'Używanie urządzeń energochłonnych z myślą o efektywności',
                        'Montaż inteligentnych systemów zarządzania energią'
                    ],
                    bad: [
                        'Używanie starszych, mniej efektywnych energetycznie urządzeń',
                        'Przegrzewanie lub nadmierne chłodzenie pomieszczeń'
                    ]
                },
                emissions: 150
            },
            {
                value: 5,
                label: 'Dużo',
                practices: {
                    good: [
                        'Inwestycja w technologię oszczędzania energii, np. rekuperacja',
                        'Kompensowanie zużycia energii przez offset węglowy'
                    ],
                    bad: [
                        'Używanie energochłonnych urządzeń na pełnej mocy bez potrzeby',
                        'Brak dbałości o energooszczędność podczas budowy lub remontu'
                    ]
                },
                emissions: 200
            }
        ],
        averageEmissions: {
            annual: 3.928,
            daily: 0.01076
        }
    },
    'Water': {
        name: 'Zużycie wody',
        levels: [
            {
                value: 1,
                label: 'Mało',
                practices: {
                    good: [
                        'Zbieranie deszczówki do podlewania roślin',
                        'Instalacja perlatory (aeratora) na kranach'
                    ],
                    bad: [
                        'Zbyt surowe ograniczenia mogą prowadzić do niewystarczającej higieny',
                        'Korzystanie z minimalnej ilości wody, co może obniżyć komfort życia'
                    ]
                },
                emissions: 10
            },
            {
                value: 2,
                label: '',
                practices: {
                    good: [
                        'Skracanie czasu brania prysznica',
                        'Wyłączanie wody podczas mycia zębów'
                    ],
                    bad: [
                        'Mycie naczyń pod bieżącą wodą zamiast korzystania ze zmywarki',
                        'Zbytnie oszczędzanie wody, co może prowadzić do braku wystarczającej czystości naczyń czy odzieży'
                    ]
                },
                emissions: 40
            },
            {
                value: 3,
                label: 'Średnio',
                practices: {
                    good: [
                        'Używanie wody szarej do spłukiwania toalet',
                        'Pranie ubrań po jednym założeniu, gdy nie jest to konieczne'
                    ],
                    bad: [
                        'Mycie samochodu pod bieżącą wodą zamiast korzystania z myjni ekologicznej',
                        'Regularne przeglądy i konserwacja instalacji wodociągowej'
                    ]
                },
                emissions: 100
            },
            {
                value: 4,
                label: '',
                practices: {
                    good: [
                        'Korzystanie z ekologicznych programów prania i zmywania',
                        'Mycie podjazdu lub tarasu wodą z węża zamiast użycia miotły'
                    ],
                    bad: [
                        'Przesadne podlewanie trawnika wodą pitną',
                        'Brak dbałości o konserwację instalacji wodnych'
                    ]
                },
                emissions: 150
            },
            {
                value: 5,
                label: 'Dużo',
                practices: {
                    good: [
                        'Inwestowanie w systemy oszczędzające wodę, np. wody szarej',
                        'Kompensowanie zużycia wody poprzez działania proekologiczne, np. sadzenie drzew'
                    ],
                    bad: [
                        'Pozostawianie wody włączonej podczas wykonywania innych czynności',
                        'Brak dbałości o konserwację instalacji wodnych, co może prowadzić do wycieków i marnotrawstwa wody'
                    ]
                },
                emissions: 200
            }
        ],
        averageEmissions: {
            annual: 10.32,
            daily: 0.0283
        }
    },
    'Trash': {
        name: 'Śmieci',
        levels: [
            {
                value: 1,
                label: 'Mało',
                practices: {
                    good: [
                        'Minimalizacja zużycia opakowań jednorazowych',
                        'Kompostowanie odpadów organicznych'
                    ],
                    bad: [
                        'Zbyt surowe ograniczenia mogą prowadzić do dyskomfortu życia',
                        'Rezygnacja z opakowań ochronnych może prowadzić do marnowania żywności'
                    ]
                },
                emissions: 10
            },
            {
                value: 2,
                label: '',
                practices: {
                    good: [
                        'Segregacja odpadów na różne frakcje',
                        'Unikanie produktów z nadmierną ilością opakowań'
                    ],
                    bad: [
                        'Niewłaściwe segregowanie, co może prowadzić do kontaminacji odpadów do recyklingu',
                        'Zbytnie oszczędzanie na opakowaniach ochronnych, co może prowadzić do uszkodzeń produktów'
                    ]
                },
                emissions: 40
            },
            {
                value: 3,
                label: 'Średnio',
                practices: {
                    good: [
                        'Regularne oddawanie surowców wtórnych do recyklingu',
                        'Korzystanie z usług recyklingu'
                    ],
                    bad: [
                        'Spalanie odpadów na własną rękę, co może prowadzić do emisji toksycznych substancji',
                        'Nieużywanie kompostownika dla odpadów organicznych'
                    ]
                },
                emissions: 100
            },
            {
                value: 4,
                label: '',
                practices: {
                    good: [
                        'Segregowanie dużych ilości odpadów',
                        'Oddawanie dużych ilości odpadów do specjalistycznych firm recyklingowych'
                    ],
                    bad: [
                        'Nadmierne zużycie jednorazowych opakowań i produktów',
                        'Brak dbałości o minimalizację odpadów'
                    ]
                },
                emissions: 150
            },
            {
                value: 5,
                label: 'Dużo',
                practices: {
                    good: [
                        'Inwestowanie w rozwiązania zmniejszające ilość odpadów',
                        'Kompensowanie dużej ilości odpadów poprzez działania proekologiczne'
                    ],
                    bad: [
                        'Brak segregacji odpadów, co prowadzi do zwiększenia ilości odpadów na składowiskach',
                        'Generowanie dużych ilości odpadów niebiodegradowalnych'
                    ]
                },
                emissions: 200
            }
        ],
        averageEmissions: {
            annual: 179.0,
            daily: 0.49
        }
    },
    'Food Consumption': {
        name: 'Spożycie żywności',
        levels: [
            {
                value: 1,
                label: 'Wegańska',
                practices: {
                    good: [
                        'Zrównoważona dieta z różnorodnymi grupami żywności.',
                        'Bogata w świeże owoce, warzywa i rośliny strączkowe.'
                    ],
                    bad: [
                        'Możliwość niedoborów składników odżywczych.',
                        'Wymaga starannego planowania posiłków.'
                    ]
                },
                emissions: 10
            },
            {
                value: 2,
                label: 'Zredukowana',
                practices: {
                    good: [
                        'Ograniczenie spożycia mięsa.',
                        'Zdrowe roślinne alternatywy.'
                    ],
                    bad: [
                        'Może prowadzić do braku różnorodności.',
                        'Możliwość wyboru przetworzonych produktów.'
                    ]
                },
                emissions: 40
            },
            {
                value: 3,
                label: 'Przeciętna',
                practices: {
                    good: [
                        'Zrównoważona dieta z różnorodnymi grupami żywności.',
                        'Łatwość w wprowadzaniu zdrowych zmian.'
                    ],
                    bad: [
                        'Wysokie spożycie mięsa i przetworzonej żywności.',
                        'Niska świadomość ekologiczna.'
                    ]
                },
                emissions: 150
            },
            {
                value: 4,
                label: 'Wegetariańska',
                practices: {
                    good: [
                        'Ograniczenie spożycia mięsa.',
                        'Zróżnicowana dieta, łatwiejsza w adaptacji.'
                    ],
                    bad: [
                        'Wysokie spożycie nabiału, co wpływa na emisje.',
                        'Możliwość wyboru przetworzonych produktów.'
                    ]
                },
                emissions: 100
            },
            {
                value: 5,
                label: 'Mięsna',
                practices: {
                    good: [
                        'Wysoka zawartość białka.',
                        'Odpowiednia dla osób z dużym zapotrzebowaniem kalorycznym.'
                    ],
                    bad: [
                        'Bardzo wysoka emisja CO2.',
                        'Zwiększone ryzyko zdrowotne.'
                    ]
                },
                emissions: 200
            }
        ],
        averageEmissions: {
            annual: 2390.0, // in KG
            daily: 6.5
        }
    },
    'Transport': {
        name: 'Transport',
        subcategories: {
            'Samoloty': {
                name: 'Samoloty',
                levels: [
                    {
                        value: 1,
                        label: 'Mało',
                        practices: {
                            good: [
                                'Ograniczanie podróży lotniczych do absolutnego minimum',
                                'Korzystanie z linii lotniczych oferujących kompensację emisji'
                            ],
                            bad: [
                                'Unikanie lotów bez istotnej potrzeby',
                                'Nadmierne korzystanie z krótkich lotów'
                            ]
                        },
                        emissions: 0.2 // 20% of average
                    },
                    {
                        value: 2,
                        label: '',
                        practices: {
                            good: [
                                'Wybieranie bezpośrednich lotów zamiast z przesiadkami',
                                'Korzystanie z ekonomicznej klasy lotów'
                            ],
                            bad: [
                                'Okazjonalne podróże lotnicze bez kompensacji',
                                'Nieoptymalne planowanie lotów zwiększające emisje'
                            ]
                        },
                        emissions: 0.4 // 40% of average
                    },
                    {
                        value: 3,
                        label: 'Średnio',
                        practices: {
                            good: [
                                'Świadome wybieranie lotów na podstawie potrzeb',
                                'Korzystanie z nowoczesnych, bardziej efektywnych samolotów'
                            ],
                            bad: [
                                'Regularne podróże lotnicze bez rozważenia alternatyw',
                                'Brak kompensacji emisji'
                            ]
                        },
                        emissions: 1 // 100% of average
                    },
                    {
                        value: 4,
                        label: '',
                        practices: {
                            good: [
                                'Optymalizacja tras lotów w celu zmniejszenia emisji',
                                'Korzystanie z opcji premium z niższym zużyciem paliwa'
                            ],
                            bad: [
                                'Częste podróże lotnicze bez rozważenia alternatyw',
                                'Brak dbałości o efektywność paliwową'
                            ]
                        },
                        emissions: 1.5 // 150% of average
                    },
                    {
                        value: 5,
                        label: 'Dużo',
                        practices: {
                            good: [
                                'Inwestowanie w programy offsetowe na dużą skalę',
                                'Wspieranie technologii redukujących emisje w lotnictwie'
                            ],
                            bad: [
                                'Nadmierne podróże lotnicze bez rekompensaty',
                                'Ignorowanie alternatywnych, bardziej ekologicznych środków transportu'
                            ]
                        },
                        emissions: 2 // 200% of average
                    }
                ],
                averageEmissions: 1000.0 // kg CO₂ per year
            },
            'Pociągi': {
                name: 'Pociągi',
                levels: [
                    {
                        value: 1,
                        label: 'Mało',
                        practices: {
                            good: [
                                'Korzystanie z pociągów tylko w niezbędnych przypadkach',
                                'Wybieranie bardziej ekologicznych tras'
                            ],
                            bad: [
                                'Unikanie pociągów bez istotnej potrzeby',
                                'Brak optymalizacji podróży'
                            ]
                        },
                        emissions: 0.2
                    },
                    {
                        value: 2,
                        label: '',
                        practices: {
                            good: [
                                'Wybieranie pociągów elektrycznych zamiast dieselowych',
                                'Optymalizacja tras i czasu podróży'
                            ],
                            bad: [
                                'Okazjonalne korzystanie z pociągów bez planowania',
                                'Brak optymalizacji podróży'
                            ]
                        },
                        emissions: 0.4
                    },
                    {
                        value: 3,
                        label: 'Średnio',
                        practices: {
                            good: [
                                'Regularne korzystanie z pociągów jako alternatywy dla samochodów',
                                'Korzystanie z usług kolejkowania CO₂'
                            ],
                            bad: [
                                'Brak optymalizacji podróży',
                                'Nieefektywne planowanie tras'
                            ]
                        },
                        emissions: 1
                    },
                    {
                        value: 4,
                        label: '',
                        practices: {
                            good: [
                                'Korzystanie z pociągów na długie dystanse zamiast samolotów',
                                'Wspieranie ekologicznych inicjatyw w transporcie kolejowym'
                            ],
                            bad: [
                                'Częste podróże bez kompensacji emisji',
                                'Brak dbałości o efektywność energetyczną'
                            ]
                        },
                        emissions: 1.5
                    },
                    {
                        value: 5,
                        label: 'Dużo',
                        practices: {
                            good: [
                                'Inwestowanie w ekologiczne technologie kolejowe',
                                'Korzystanie z pociągów na pełnej mocy bez potrzeby'
                            ],
                            bad: [
                                'Nadmierne korzystanie z pociągów bez rozważenia alternatyw',
                                'Ignorowanie efektywności energetycznej'
                            ]
                        },
                        emissions: 2
                    }
                ],
                averageEmissions: 500.0 // kg CO₂ per year
            },
            'Komunikacja miejska': {
                name: 'Komunikacja miejska',
                levels: [
                    {
                        value: 1,
                        label: 'Mało',
                        practices: {
                            good: [
                                'Korzystanie z komunikacji miejskiej tylko w niezbędnych przypadkach',
                                'Wybieranie bardziej ekologicznych środków transportu'
                            ],
                            bad: [
                                'Unikanie komunikacji miejskiej bez istotnej potrzeby',
                                'Brak optymalizacji podróży'
                            ]
                        },
                        emissions: 0.2
                    },
                    {
                        value: 2,
                        label: '',
                        practices: {
                            good: [
                                'Wybieranie elektrycznych autobusów i tramwajów',
                                'Optymalizacja tras i czasu podróży'
                            ],
                            bad: [
                                'Okazjonalne korzystanie z komunikacji miejskiej bez planowania',
                                'Brak optymalizacji podróży'
                            ]
                        },
                        emissions: 0.4
                    },
                    {
                        value: 3,
                        label: 'Średnio',
                        practices: {
                            good: [
                                'Regularne korzystanie z komunikacji miejskiej jako alternatywy dla samochodów',
                                'Korzystanie z usług kolejkowania CO₂'
                            ],
                            bad: [
                                'Brak optymalizacji podróży',
                                'Nieefektywne planowanie tras'
                            ]
                        },
                        emissions: 1
                    },
                    {
                        value: 4,
                        label: '',
                        practices: {
                            good: [
                                'Korzystanie z komunikacji miejskiej na długie dystanse zamiast samochodów',
                                'Wspieranie ekologicznych inicjatyw w transporcie publicznym'
                            ],
                            bad: [
                                'Częste podróże bez kompensacji emisji',
                                'Brak dbałości o efektywność energetyczną'
                            ]
                        },
                        emissions: 1.5
                    },
                    {
                        value: 5,
                        label: 'Dużo',
                        practices: {
                            good: [
                                'Inwestowanie w ekologiczne technologie komunikacji miejskiej',
                                'Korzystanie z komunikacji miejskiej na pełnej mocy bez potrzeby'
                            ],
                            bad: [
                                'Nadmierne korzystanie z komunikacji miejskiej bez rozważenia alternatyw',
                                'Ignorowanie efektywności energetycznej'
                            ]
                        },
                        emissions: 2
                    }
                ],
                averageEmissions: 400.0 // kg CO₂ per year
            },
            'Samochody': {
                name: 'Samochody',
                levels: [
                    {
                        value: 1,
                        label: 'Mało',
                        practices: {
                            good: [
                                'Korzystanie z samochodu tylko w absolutnie niezbędnych przypadkach',
                                'Wybieranie samochodów elektrycznych lub hybrydowych'
                            ],
                            bad: [
                                'Unikanie samochodów bez istotnej potrzeby',
                                'Brak optymalizacji podróży'
                            ]
                        },
                        emissions: 0.2
                    },
                    {
                        value: 2,
                        label: '',
                        practices: {
                            good: [
                                'Wybieranie samochodów o niskim zużyciu paliwa',
                                'Regularne serwisowanie pojazdu w celu optymalizacji efektywności'
                            ],
                            bad: [
                                'Okazjonalne korzystanie z samochodów bez planowania',
                                'Brak optymalizacji podróży'
                            ]
                        },
                        emissions: 0.4
                    },
                    {
                        value: 3,
                        label: 'Średnio',
                        practices: {
                            good: [
                                'Regularne korzystanie z samochodu jako środka transportu',
                                'Korzystanie z usług kolejkowania CO₂'
                            ],
                            bad: [
                                'Brak optymalizacji podróży',
                                'Nieefektywne planowanie tras'
                            ]
                        },
                        emissions: 1
                    },
                    {
                        value: 4,
                        label: '',
                        practices: {
                            good: [
                                'Wybieranie samochodów z najnowszą technologią redukującą emisje',
                                'Planowanie tras w celu zmniejszenia przebiegu'
                            ],
                            bad: [
                                'Częste podróże samochodem bez kompensacji emisji',
                                'Brak optymalizacji podróży'
                            ]
                        },
                        emissions: 1.5
                    },
                    {
                        value: 5,
                        label: 'Dużo',
                        practices: {
                            good: [
                                'Inwestowanie w samochody elektryczne o wysokiej efektywności',
                                'Korzystanie z samochodów na pełnej mocy bez potrzeby'
                            ],
                            bad: [
                                'Nadmierne korzystanie z samochodów bez rozważenia alternatyw',
                                'Ignorowanie efektywności paliwowej'
                            ]
                        },
                        emissions: 2
                    }
                ],
                averageEmissions: 800.0 // kg CO₂ per year
            }
        }
    }
};

export default categoriesData;
